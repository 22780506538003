@import './variables';
.hero {
  &.bg {
      display: block;
      position: relative; 
      background: linear-gradient(#FF7600, #8B040E);
      background-repeat: no-repeat;
      background-image: url("./assets/images/page_bg.jpg");
      background-position: 50%;
      background-size: cover;
      max-width: 480px;
      margin: 0 auto; 
  }
  .bgloader {
    display: block;
    position: relative; 
    // background: linear-gradient(#FF7600, #8B040E);
    background-repeat: no-repeat;
    // background-image: url("./assets/images/page_bg.jpg");
    background-position: 50%;
    background-size: cover;
    max-width: 480px;
    margin: 0 auto; 
  }
}



.subBg{
  background: linear-gradient(#F9C35F, #F68C64) !important; 
}
.ld-bg
{
align-items: center;
background-color: rgba(0, 0, 0, .2);
min-height: 100vh;
position: fixed;
top: 0;
width: 480px;
z-index: 1000;
}
.navbar {
  &.is-mobile {
      min-height: auto;
      .navbar-menu {
          display: block;
          background-color: transparent;
          box-shadow: none;
          padding: 0.3rem 0;
          .navbar-item {
              display: inline-flex;
              vertical-align: text-top;
              &:hover {
                  background-color: rgba(250,250,250, 0.4);
              }
          }
      }
  }
}

.container {
  &.m-w {
      width: 90%;
  }
}

.v-center {
  display: flex !important;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.ml-auto {
  margin-left: auto
}
.text-white
{
  color:white !important;
}
.bg
{
  background-color: none;
  border: 1px solid transparent;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
  font-size: .75rem;
  font-weight: 500;
}
.slider-card{
  card
{

  border-radius: 0.25rem;
  // box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
}
.flex{
  display: flex;
  flex-direction: row;
  width:100%;
  justify-content: space-between;
  white-space: break-spaces;
}
.word-break
{
  white-space: break-spaces;
}
.flex-left{
  justify-content: flex-start;
  text-align: left;
  font-size:.75rem;
  overflow: hidden;
  display: -webkit-box;  /* Required for multi-line ellipses */
  -webkit-box-orient: vertical;  /* Defines the box orientation */
  -webkit-line-clamp: 1;  /* Number of lines to display before truncating */
  overflow: hidden; 
}
.flex-right{
  justify-content: flex-end;
  text-align: right;
  align-items: flex-end;
}
.display
{
  display: flex !important;
}
.star-icon{
  height:.75rem;
  margin-top: .2rem;
}
.img-radius{
  border-radius: .25rem;
}
.play-btn
{
  max-width:130px;
}
@media only screen and (max-width: 350px) {
.play-btn
{
max-width: 60px;
max-height:70px;
}
}
.game-desc
{
 white-space: break-spaces;
 text-align: justify;
 margin-top:.5rem;
 line-height:2 !important;
}
.cat-icon
{
  margin-left: 1rem;
  height: 1rem;
  vertical-align: middle;
  margin-top:-0.5rem;
}
.loader-container {
    position: absolute;
    height: 100vh;
    top:0;
    left:0;
    width:100%;
    max-width: 480px;
    z-index:2000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lds
{
  position: absolute;
  height: 100vh;
  top:50%;
  left:50%;
  margin-left: -7%;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 0.6s linear infinite;
}
.lds::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #FF3D00;
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

nav{
  width:100%;
}

.show
{
  display: block;
}
.hide
{
  display: none;
}
.slider-img{
  margin-top: -5%;
  margin-bottom: 2%;
}
.fixed-width
{
  width:33.333%;
  text-align: left;
}
.rating-icon
{
height:11px;
width:10px;
}
.p-0
{
padding:0;
}
.play
{
width:30%;
}
.m-auto{
margin:auto;
}
.desc
{
position:absolute !important;
bottom: -4rem !important;
}
.relative
{
position: relative;
}
.mt-8
{
margin-top:3rem !important;
}
.align-left
{
text-align: left;
width:70%;
}
.mt-6
{
margin-top:2rem !important; 
}
.links
{
display: flex;
flex-direction: row;
justify-content: baseline;
text-transform: capitalize;
}
.link
{
margin-top:6px;
margin-left:6px;
}
.text-black
{
color:black;
}
.display {
display: flex!important;
flex-direction: row;
justify-content: space-between;
white-space: break-spaces;
}
.info-card
{
  display: flex!important;
flex-direction: column;
justify-content: space-between;
}
.text-lg {
font-size: larger!important;
}
.bold {
font-weight: 900;
}
.text-start {
text-align: left;
}
.semibold
{
font-weight: 400;
}
.btn
{
background: rgb(2,0,36);
background: $theme-rustyOrange;
border-radius: 5px;
margin:1rem;
border:2px solid transparent;
padding:.5rem;
text-transform: uppercase;
}

.capitalize
{
text-transform: capitalize;
}
.view-btn{
  background: linear-gradient(270deg,$theme-lightrustyOrange,$theme-lightdarkOrange) !important;
  border-radius: 0.25rem;
}
.language-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.lang-code
{
  margin-left:2rem;
  color:white !important;
}
.language-option {
  padding: 20px 20px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border: 2px solid $theme-rustyOrange;
  border-radius: 5px;
  font-weight: 900;
  color:white;
}


.language-option.selected {
  background-color: $theme-rustyOrange;
  color: white;
  border: 1px solid $theme-rustyOrange;
}
.lang-icon
{
  height:1.5rem;
}