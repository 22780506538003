.terms-conditions-container {
    max-width: 800px;
    padding: 0 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    white-space: break-spaces;
    h1, h2 {
        color: white;
        font-size: x-large;
    }

    p, li {
        color: white;
        font-size: 16px;
        line-height: 1.6;
    }
}

